import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';

//Views
import Main from './view/content/Main';
import Services from './view/content/Services';
import Contact from './view/content/Contact';

// Import styles
import './App.css';
import whatsAppButtonSmall from './view/img/WhatsAppButtonGreenSmall.png';
import whatsAppButtonMobile from './view/img/WhatsAppButtonGreenMobile.png';

// Import components
import  { Component } from 'react';
import Navegation from './view/components/Navigation';
import Footer from './view/components/Footer';
import WhoWeAre from './view/content/WhoWeAre';
import Donations from './view/content/Donations';

class App extends Component {

    render() {

        ReactGA.initialize('UA-138213870-2');

        const language = navigator.language? navigator.language.substring(0,2): 'en';

        return (
            <Router>
                <div className="wrapper">
                    <Navegation/>
                    <div className="content w-100">

                        <div className="page">
                        <Switch>
                            <Redirect from='/' to={`/${language}/main`} exact/>
                            <Route path='/:language/main' component={Main} exact/>
                            <Route path='/:language/services' component={Services} exact/>
                            <Route path='/:language/whoweare' component={WhoWeAre} exact/>
                            <Route path='/:language/donations' component={Donations} exact/>
                            <Route path='/:language/contact' component={Contact} exact/>
                        </Switch>
                        </div>
                        <Footer/>
                        <a class="whatsapp-button-small" aria-label="Chat on WhatsApp" href="https://wa.me/573174344696" rel="noopener noreferrer" target="_blank"><img alt="Chat on WhatsApp" src={whatsAppButtonSmall} /></a>
                        <a class="whatsapp-button-mobile" aria-label="Chat on WhatsApp" href="https://wa.me/573174344696" rel="noopener noreferrer" target="_blank"><img alt="Chat on WhatsApp" src={whatsAppButtonMobile} /></a>
                    </div>
                    <div id="overlay" className="hidden"></div>

                </div>
            </Router>
        );
    }
}

export default App;