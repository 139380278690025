import React from 'react';
import ReactGA from 'react-ga';

import { Redirect } from 'react-router-dom';

// Import styles
import '../styles/contact.css';

// Import Components
import { Component } from 'react';

// Component Funtion
const NOT_SUBMITTED = 0;
const SUBMITTING = 1;
const SUBMITTED = 2;

class Contact extends Component {
  constructor() {
    window.scrollTo(0, 0);
    super();

    this.state = {
      submitionStatus: NOT_SUBMITTED,
    };
    this.emailRef = React.createRef();
    this.msjRef = React.createRef();
  }

  submitHandler = (event) => {
    event.preventDefault();
    const email = this.emailRef.current.value;
    const msj = this.msjRef.current.value;

    this.setState({ submitionStatus: SUBMITTING });

    fetch('https://creadoresdevalor.com/email/email.php', {
      method: 'POST',
      body: JSON.stringify({ email: email, msj: msj }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.emailRef.current.value = '';
        this.msjRef.current.value = '';
        this.setState({ submitionStatus: SUBMITTED });
        setTimeout(() => {
          this.setState({ submitionStatus: NOT_SUBMITTED });
        }, 5000);
      });
  };

  render() {
    ReactGA.pageview('/contact');

    const implementedLanguages = ['en', 'es'];

    const { language } = this.props.match.params;

    if (!implementedLanguages.includes(language)) {
      return <Redirect to="/" />;
    }

    return (
      <section>
        <form
          className="article_contact bg-dark dividerContact"
          onSubmit={this.submitHandler}
        >
          {language === 'en' && <h3 className="title">Contact Us</h3>}
          {language === 'es' && <h3 className="title">Contáctanos</h3>}
          {language === 'en' && (
            <p className="description">
              Cellphone: +57 317 434 4696
              <br />
              WhatsApp: +57 317 434 4696
              <br />
              Email: memphis@creadoresdevalor.com
            </p>
          )}
          {language === 'es' && (
            <p className="description">
              Celular: +57 317 434 4696
              <br />
              WhatsApp: +57 317 434 4696
              <br />
              Email: memphis@creadoresdevalor.com
            </p>
          )}

          {this.updateButton(language)}

          {language === 'en' && (
            <input
              className="mail"
              type="text"
              id="email"
              name="email"
              placeholder="Email address"
              ref={this.emailRef}
              required
            />
          )}
          {language === 'es' && (
            <input
              className="mail"
              type="text"
              id="email"
              name="email"
              placeholder="Correo electrónico"
              ref={this.emailRef}
              required
            />
          )}

          {language === 'en' && (
            <textarea
              className=""
              id="subject"
              name="subject"
              ref={this.msjRef}
              placeholder="Type your message..."
            />
          )}
          {language === 'es' && (
            <textarea
              className=""
              id="subject"
              name="subject"
              ref={this.msjRef}
              placeholder="Escribe tu mensaje..."
            />
          )}
        </form>
      </section>
    );
  }

  updateButton(language) {
    const buttonTextOptions = {
      en: ['Submit', '', 'Submitted!'],
      es: ['Enviar', '', '¡Enviado!'],
    };

    const buttonMessage =
      buttonTextOptions[language][this.state.submitionStatus];

    return (
      <div className="loading-btn">
        <input
          className="button1 btn btn-info"
          type="submit"
          value={buttonMessage}
        />
        {this.state.submitionStatus === SUBMITTING && (
          <div className="loading-icon"></div>
        )}
      </div>
    );
  }
}

export default Contact;
