import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// Import styles
import '../styles/services.css';
import chicago3LG from '../img/chicago6.png';
import chicago3MD from '../img/chicago6_md.png';
import chicago3SM from '../img/chicago6_sm.png';

import star from '../img/star.png';
import service1 from '../img/valuation.png';
import service2 from '../img/adquisiciones.png';
import service3 from '../img/aumento.png';
import service4 from '../img/planeacion.png';

// Import Components
import  { Component } from 'react';

// Component Funtion
class Services extends Component{

    render(){

        ReactGA.pageview('/services');

        window.scrollTo(0, 0)

        const implementedLanguages = ['en', 'es'];

        const { language } = this.props.match.params;

        if(!implementedLanguages.includes(language)){
            return <Redirect to='/'/>
        }

        return (
            <section>

                <article className="article_1">
                    <svg className="greyPolygon" width="100%" height="150">
                        <polygon points={"0,0 0,150 3000,150"}/>
                    </svg>
                    <img alt="Img" src={chicago3LG} className="img-background d-none d-lg-block"/>
                    <img alt="Img" src={chicago3MD} className="img-background d-none d-md-block d-lg-none"/>
                    <img alt="Img" src={chicago3SM} className="img-background d-md-none"/>
                    {language === 'en' && <h3 className="title">KNOW THE SERVICES<br />WE HAVE FOR YOU</h3>}
                    {language === 'es' && <h3 className="title">CONOCE LOS SERVICIOS<br/>QUE TENEMOS PARA TI</h3>}
                    {/*<p className="description d-none d-xl-block">Texto introductorio.</p>*/}
                    {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                </article>

                <article className="article_2 bg-dark divider420">
                    {language === 'en' && <h3 className="title">Your benefits with Creators of Value</h3>}
                    {language === 'es' && <h3 className="title">Tus beneficios con Creadores de Valor</h3>}
                    {/*<p className="description">Texto complementario</p>*/}

                    {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    <div className="beneficio_1 bg-white text-dark ">
                        <img alt="Img" src={star} className="img-responsive mx-auto d-none d-xl-block"/>
                        {language === 'en' && <p className="mt-4 d-none d-xl-block">Diagnose the health of your business</p>}
                        {language === 'es' && <p className="mt-4 d-none d-xl-block">Diagnosticar la salud de tu negocio</p>}
                        <div className="mt-3 img-responsive d-inline ml-4 float-left d-xl-none">
                            <img alt="Img" src={star} width="30"/>
                        </div>
                        <div className="mt-3 mx-2 float-right d-inline d-xl-none w-75">
                            {language === 'en' && <p>Diagnose the health of your business</p>}
                            {language === 'es' && <p>Diagnosticar la salud de tu negocio</p>}
                        </div>

                    </div>
                    <div className="beneficio_2 bg-white text-dark">
                        <img alt="Img" src={star} className="img-responsive mx-auto d-none d-xl-block"/>
                        {language === 'en' && <p className="mt-4 d-none d-xl-block">Know the market value of your company</p>}
                        {language === 'es' && <p className="mt-4 d-none d-xl-block">Conocer el valor de mercado de tu empresa</p>}
                        <div className="mt-3 img-responsive d-inline ml-4 float-left d-xl-none">
                            <img alt="Img" src={star} width="30"/>
                        </div>
                        <div className="mt-3 mx-2 float-right d-inline d-xl-none w-75">
                            {language === 'en' && <p>Know the market value of your company</p>}
                            {language === 'es' && <p>Conocer el valor de mercado de tu empresa</p>}
                        </div>
                    </div>
                    <div className="beneficio_3 bg-white text-dark">
                        <img alt="Img" src={star} className="img-responsive mx-auto d-none d-xl-block"/>
                        {language === 'en' && <p className="mt-4 d-none d-xl-block">Select the best strategy for your company</p>}
                        {language === 'es' && <p className="mt-4 d-none d-xl-block">Seleccionar la mejor estrategia para tu empresa</p>}
                        <div className="mt-3 img-responsive d-inline ml-4 float-left d-xl-none">
                            <img alt="Img" src={star} width="30"/>
                        </div>
                        <div className="mt-3 mx-2 float-right d-inline d-xl-none w-75">
                            {language === 'en' && <p>Select the best strategy for your company</p>}
                            {language === 'es' && <p>Seleccionar la mejor estrategia para tu empresa</p>}
                        </div>
                    </div>
                    <div className="beneficio_4 bg-white text-dark">
                        <img alt="Img" src={star} className="img-responsive mx-auto d-none d-xl-block"/>
                        {language === 'en' && <p className="mt-4 d-none d-xl-block">Have certainty to buy, sell or invest</p>}
                        {language === 'es' && <p className="mt-4 d-none d-xl-block">Tener certeza para comprar, vender o invertir</p>}
                        <div className="mt-3 img-responsive d-inline ml-4 float-left d-xl-none">
                            <img alt="Img" src={star} width="30"/>
                        </div>
                        <div className="mt-3 mx-2 float-right d-inline d-xl-none w-75">
                            {language === 'en' && <p>Have certainty to buy, sell or invest</p>}
                            {language === 'es' && <p>Tener certeza para comprar, vender o invertir</p>}
                        </div>
                    </div>
                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service1} className="img-responsive-full mx-auto d-block"/>
                        {language === 'en' && <h3 className="title1 text-dark">Business Valuation</h3>}
                        {language === 'es' && <h3 className="title1 text-dark">Valoración de Empresas</h3>}
                        {language === 'en' && <p className="description1 text-dark">The commercial value of your company is related to its ability to generate cash flow.</p>}
                        {language === 'es' && <p className="description1 text-dark">El valor comercial de tu empresa está relacionado con su capacidad de generar flujo de caja.</p>}
                        {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                        {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    </div>

                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service2} className="img-responsive-full mx-auto d-block"/>
                        {language === 'en' && <h3 className="title1 text-dark">Mergers and Acquisitions (M&A)</h3>}
                        {language === 'es' && <h3 className="title1 text-dark">Fusiones y Adquisiciones (M&A)</h3>}
                        {language === 'en' && <p className="description1 text-dark">By uniting two companies, savings are generated and economic value is created.</p>}
                        {language === 'es' && <p className="description1 text-dark">Al unir dos empresas se generan ahorros y se crea valor económico.</p>}
                        {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                        {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    </div>

                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service3} className="img-responsive-full mx-auto d-block"/>
                        {language === 'en' && <h3 className="title1 text-dark">Profit increase plan</h3>}
                        {language === 'es' && <h3 className="title1 text-dark">Plan aumento de utilidades</h3>}
                        {language === 'en' && <p className="description1 text-dark">We help you implement strategies that increase your profits and your cash flow.</p>}
                        {language === 'es' && <p className="description1 text-dark">Te ayudamos a implementar estrategias que aumenten tus utilidades y tu flujo de caja.</p>}
                        {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                        {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    </div>

                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service4} className="img-responsive-full mx-auto d-block"/>
                        {language === 'en' && <h3 className="title1 text-dark">Strategic Planning</h3>}
                        {language === 'es' && <h3 className="title1 text-dark">Planeación Estratégica</h3>}
                        {language === 'en' && <p className="description1 text-dark">Face the turbulence of future markets by anticipating now with simulated scenarios.</p>}
                        {language === 'es' && <p className="description1 text-dark">Afronta la turbulencia de los mercados futuros anticipándote desde ahora con escenarios simulados.</p>}
                        {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                        {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    </div>

                </article>

            </section>
        );
    };
}

export default Services;