import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// Import styles
import '../styles/main.css';
import chicago3LG from '../img/chicago3.jpg';
import chicago3MD from '../img/chicago3_md.jpg';
import chicago3SM from '../img/chicago3_sm.jpg';

import chicago4LG from '../img/chicago4.jpg';
import chicago4MD from '../img/chicago4_md.jpg';
import chicago4SM from '../img/chicago4_sm.jpg';

import clientImg1 from '../img/clients/coomeva.png';
import clientImg2 from '../img/clients/omnicon.png';
import clientImg3 from '../img/clients/zonaFranca.jpeg';
import clientImg4 from '../img/clients/novatec.png';

import IFAC from '../img/IFAC_2008.png';
import leaveLeft from '../img/leaveLeft.svg';
import leaveRight from '../img/leaveRight.svg';

// Import Components
import  { Component } from 'react';

// Component Funtion
class Main extends Component{

    render(){

        ReactGA.pageview('/main');

        window.scrollTo(0, 0);

        const implementedLanguages = ['en', 'es'];

        const { language } = this.props.match.params;

        if(!implementedLanguages.includes(language)){
            return <Redirect to='/'/>
        }

        return (
            <section>

                <article id="article_1">
                    <svg className="greyPolygon" width="100%" height="150">
                        <polygon points={"0,0 0,150 3000,150"}/>
                    </svg>
                    <img alt="Img" src={chicago3LG} className="img-background d-none d-lg-block"/>
                    <img alt="Img" src={chicago3MD} className="img-background d-none d-md-block d-lg-none"/>
                    <img alt="Img" src={chicago3SM} className="img-background d-md-none"/>
                    {language === 'en' && <h3 className="title">DISCOVER HOW TO SOLVE<br />YOUR FINANCIAL PROBLEMS</h3>}
                    {language === 'es' && <h3 className="title">DESCUBRE CÓMO SOLUCIONAR<br />TUS PROBLEMAS FINANCIEROS</h3>}
                    {language === 'en' && <p className="description d-none d-xl-block">We project your cash flow to anticipate and improve your financial results.</p>}
                    {language === 'es' && <p className="description d-none d-xl-block">Proyectamos tu flujo de caja para anticipar y mejorar tus resultados financieros.</p>}
                    {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                </article>

                <article id="article_2" className="bg-dark divider420">
                    {language === 'en' && <h3 className="title">We have more than 20 years of experience</h3>}
                    {language === 'es' && <h3 className="title">Tenemos más de 20 años de experiencia</h3>}
                    {language === 'en' && <p className="description">Our methods are based on good financial practices, our clients prove it.</p>}
                    {language === 'es' && <p className="description">Nuestros métodos se basan en buenas prácticas financieras, nuestros clientes lo demuestran.</p>}

                    <div className="ifac">
                    <img alt="Img" src={leaveLeft} className="h-100"/>
                    <img alt="Img" src={IFAC} className="h-100"/>
                    <img alt="Img" src={leaveRight} className="h-100"/>
                    </div>

                    {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    <div className="info_1 bg-white text-dark ">
                        <img alt="Img" src={clientImg1} className="img-responsive mx-auto d-block"/>
                    </div>
                    <div className="info_2 bg-white text-dark">
                        <img alt="Img" src={clientImg2} className="img-responsive mx-auto d-block"/>
                    </div>
                    <div className="info_3 bg-white text-dark">
                        <img alt="Img" src={clientImg3} className="img-responsive mx-auto d-block"/>
                    </div>
                    <div className="info_4 bg-white text-dark">
                        <img alt="Img" src={clientImg4} className="img-responsive mx-auto d-block"/>
                    </div>
                </article>

                <article id="article_3">
                    <img alt="Img" src={chicago4LG} className="img-background d-none d-lg-block"/>
                    <img alt="Img" src={chicago4MD} className="img-background d-none d-md-block d-lg-none"/>
                    <img alt="Img" src={chicago4SM} className="img-background d-md-none"/>
                    {language === 'en' && <h3 className="title">We diagnose your financial health</h3>}
                    {language === 'es' && <h3 className="title">Diagnosticamos tu salud financiera</h3>}
                    {language === 'en' && <p className="description d-none d-xl-block">Learn how to increase your profits <br />effectively.</p>}
                    {language === 'es' && <p className="description d-none d-xl-block">Conoce cómo aumentar tus utilidades <br/>de forma efectiva.</p>}
                    {language === 'en' && <Link className="buttonStart btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="buttonStart btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                    {language === 'en' && <Link className="buttonHow btn" to="/en/services">Discover how</Link>}
                    {language === 'es' && <Link className="buttonHow btn" to="/es/services">Descubre cómo</Link>}
                </article>

                <article id="article_4" className="bg-white divider600main">
                    {language === 'en' && <h3 className="title text-dark">The latest news</h3>}
                    {language === 'es' && <h3 className="title text-dark">Las últimas noticias</h3>}
                    {language === 'en' && <p className="description text-secondary d-none d-xl-block">Stay informed of the latest events and how they affect your sector.</p>}
                    {language === 'es' && <p className="description text-secondary d-none d-xl-block">Mantente informado de los últimos acontecimientos y cómo estos afectan a tu sector.</p>}


                    <div className="new_2">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FQKy71qd7nc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                    {language === 'en' && <a className="button1 btn btn-info" href="https://www.facebook.com/CreadoresDeValor" target="_blank">See more</a>}
                    {language === 'es' && <a className="button1 btn btn-info" href="https://www.facebook.com/CreadoresDeValor" target="_blank">Ver más</a>}

                </article>

            </section>
        );
    };
}

export default Main;