import React from 'react';
import { Redirect  } from 'react-router-dom';
import ReactGA from 'react-ga';

// Import styles
import '../styles/donations.css';

import chicago3LG from '../img/donations.png';
import chicago3MD from '../img/donations_md.png';
import chicago3SM from '../img/donations_sm.png';

import service1 from '../img/valuation.png';
import service2 from '../img/adquisiciones.png';
import service3 from '../img/aumento.png';

// Import Components
import  { Component } from 'react';

// Component Funtion
class Donations extends Component{

    render(){

        ReactGA.pageview('/services');

        window.scrollTo(0, 0)

        const implementedLanguages = ['en', 'es'];

        const { language } = this.props.match.params;

        if(!implementedLanguages.includes(language)){
            return <Redirect to='/'/>
        }

        return (
            <section>

                <article id="article_1">
                    <svg className="greyPolygon" width="100%" height="150">
                        <polygon points={"0,0 0,150 3000,150"}/>
                    </svg>
                    <img alt="Img" src={chicago3LG} className="img-background d-none d-lg-block"/>
                    <img alt="Img" src={chicago3MD} className="img-background d-none d-md-block d-lg-none"/>
                    <img alt="Img" src={chicago3SM} className="img-background d-md-none"/>
                    {language === 'en' && <h3 className="title">DONATIONS</h3>}
                    {language === 'es' && <h3 className="title">DONACIONES</h3>}
                    {/* <p className="description d-none d-xl-block">Proyectamos tu flujo de caja para anticipar y mejorar tus resultados financieros.</p> */}
                    {language === 'en' && <a className="button1 btn btn-info" href="https://www.paypal.com/donate/?hosted_button_id=Z7N3GJ4SY78H2&source=url" target="_blank">Donate</a>}
                    {language === 'es' && <a className="button1 btn btn-info" href="https://www.paypal.com/donate/?hosted_button_id=Z7N3GJ4SY78H2&source=url" target="_blank">Donar</a>}
                </article>

            </section>
        );
    };
}

export default Donations;