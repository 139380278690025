import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// Import styles
import '../styles/whoweare.css';

import chicago3LG from '../img/team.png';
import chicago3MD from '../img/team_md.png';
import chicago3SM from '../img/team_sm.png';

import service1 from '../img/memphis.png';
import service2 from '../img/adelaida.png';
import service3 from '../img/paola.png';

// Import Components
import  { Component } from 'react';

// Component Funtion
class WhoWeAre extends Component{

    render(){

        ReactGA.pageview('/services');

        window.scrollTo(0, 0)

        const implementedLanguages = ['en', 'es'];

        const { language } = this.props.match.params;

        if(!implementedLanguages.includes(language)){
            return <Redirect to='/'/>
        }

        return (
            <section>

                <article id="article_1">
                    <svg className="greyPolygon" width="100%" height="150">
                        <polygon points={"0,0 0,150 3000,150"}/>
                    </svg>
                    <img alt="Img" src={chicago3LG} className="img-background d-none d-lg-block"/>
                    <img alt="Img" src={chicago3MD} className="img-background d-none d-md-block d-lg-none"/>
                    <img alt="Img" src={chicago3SM} className="img-background d-md-none"/>
                    {language === 'en' && <h3 className="title text-dark">ABOUT US</h3>}
                    {language === 'es' && <h3 className="title text-dark">CONÓCENOS</h3>}
                    {language === 'en' && <Link className="button1 btn btn-info" to="/en/contact">Start now!</Link>}
                    {language === 'es' && <Link className="button1 btn btn-info" to="/es/contact">¡Inicia ya!</Link>}
                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service1} className="img-responsive-full mx-auto d-block"/>
                        <h3 className="title1 text-dark">Memphis Viveros</h3>
                        {language === 'en' && <p className="description1 text-dark">Economist - University of Valle<br/>Finance specialist - ICESI University<br/>Master in Finance - ICESI University - Illinois Institute of Technology (Chicago)<br/>Director of Creators of Value</p>}
                        {language === 'es' && <p className="description1 text-dark">Economista - Universidad del Valle<br/>Especialista en Finanzas - Universidad ICESI<br/>Master en Finanzas - Universidad ICESI - Illinois Institute of Technology (Chicago)<br/>Director de Creadores de Valor</p>}
                    </div>

                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service2} className="img-responsive-full mx-auto d-block"/>
                        <h3 className="title1 text-dark">Adelaida Giraldo Mariño</h3>
                        {language === 'en' && <p className="description1 text-dark">Public accountant - University of Valle<br/>Expert in accounting processes<br/>Former Accountant - Notaría Primera Palmira<br/>Accountant and Deputy Manager of Creators of Value</p>}
                        {language === 'es' && <p className="description1 text-dark">Contadora Pública - Universidad del Valle<br/>Experta en procesos contables<br/>Ex Contadora - Notaría Primera Palmira<br/>Contadora y Subgerente de Creadores de Valor</p>}
                    </div>

                </article>

                <article className="article_3 bg-white dividerServices">

                    <div className="service_1">
                        <img alt="Img" src={service3} className="img-responsive-full mx-auto d-block"/>
                        <h3 className="title1 text-dark">Paola Andrea Bobadilla Ortíz</h3>
                        {language === 'en' && <p className="description1 text-dark">Economist - Universidad Autónoma de Occidente<br/>Social Management Specialist - Pontifical Xavierian Univeristy<br/>Master in Administration - University of La Rioja (Spain)</p>}
                        {language === 'es' && <p className="description1 text-dark">Economista - Universidad Autónoma de Occidente<br/>Especialista en Gerencia Social - Pontificia Universidad Javeriana<br/>Master en Administración - Universidad de La Rioja (España)</p>}
                    </div>

                </article>

            </section>
        );
    };
}

export default WhoWeAre;