import React from 'react';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Import js
import '../js/navegation.js';

// Import styles
import '../styles/navigation.css';

// Import Img
import logoImg from '../img/logoSuperior.png';

// Import components

class Navigation extends Component{
    render(){

        const implementedLanguages = ['en', 'es'];

        let language = window.location.pathname.substring(1,3);

        if(!implementedLanguages.includes(language)){ language = 'en'}

        return (
                    <div className="navegation">

                        {/* Sidebar, displayed under large size*/}
                        <aside id="sidebar" className="hidden navbar navbar-dark bg-dark align-content-start d-lg-none">

                            {/* Pages */}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    {language === 'en' && <NavLink className="sidebarCollapse nav-link" to="services">Services</NavLink>}
                                    {language === 'es' && <NavLink className="sidebarCollapse nav-link" to="services">Servicios</NavLink>}
                                </li>
                                <li className="nav-item">
                                    {language === 'en' && <NavLink className="sidebarCollapse nav-link" to="whoweare">About</NavLink>}
                                    {language === 'es' && <NavLink className="sidebarCollapse nav-link" to="whoweare">Conócenos</NavLink>}
                                </li>
                                <li className="nav-item">
                                    {language === 'en' && <NavLink className="sidebarCollapse nav-link" to="donations">Donations</NavLink>}
                                    {language === 'es' && <NavLink className="sidebarCollapse nav-link" to="donations">Donaciones</NavLink>}
                                </li>
                                <li className="nav-item">
                                    {language === 'en' && <NavLink className="sidebarCollapse nav-link" to="contact">Contact</NavLink>}
                                    {language === 'es' && <NavLink className="sidebarCollapse nav-link" to="contact">Contáctanos</NavLink>}
                                </li>
                            </ul>
                        </aside>


                        {/* Header */}
                        <header className="bg-dark">

                            {/* Header's navegation bar */}
                            <nav id="navbar" className="hidden navbar navbar-expand navbar-dark bg-dark w-100">

                                {/* Navegation bar left size */}
                                <div className="container-fluid justify-content-start">

                                    {/* sidebar button, displayed under large size */}
                                    <button type="button" className="sidebarCollapse btn text-white d-lg-none">
                                        <i className="fas fa-align-left"></i>
                                    </button>

                                    {/* Website name */}
                                    <div className="navbar-header">
                                        <NavLink className="navbar-brand my-1 mx-4" to={'/' + language + '/main'}><img src={logoImg} height="50px" alt="logo"/></NavLink>
                                    </div>

                                    {/* Pages, displayed over large size*/}
                                    <ul className="navbar-nav d-none d-lg-flex">
                                        <li className="nav-item">
                                            {language === 'en' && <NavLink className="nav-link" to="services">Services</NavLink>}
                                            {language === 'es' && <NavLink className="nav-link" to="services">Servicios</NavLink>}
                                        </li>
                                        <li className="nav-item">
                                            {language === 'en' && <NavLink className="nav-link" to="whoweare">About</NavLink>}
                                            {language === 'es' && <NavLink className="nav-link" to="whoweare">Conócenos</NavLink>}
                                        </li>
                                        <li className="nav-item">
                                            {language === 'en' && <NavLink className="nav-link" to="donations">Donations</NavLink>}
                                            {language === 'es' && <NavLink className="nav-link" to="donations">Donaciones</NavLink>}
                                        </li>
                                        <li className="nav-item">
                                            {language === 'en' && <NavLink className="nav-link" to="contact">Contact</NavLink>}
                                            {language === 'es' && <NavLink className="nav-link" to="contact">Contáctanos</NavLink>}
                                        </li>
                                    </ul>

                                </div>

                                {/* Navegation bar right size */}
                                <div className="container-fluid justify-content-end">

                                    {/* Pages */}
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                        </li>
                                    </ul>

                                </div>
                            </nav>
                        </header>

                    </div>


        );
    };
};

export default Navigation;