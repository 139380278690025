import React from 'react';

// Import js

// Import styles
import '../styles/footer.css';

// Import Img
import logoImg from '../img/logoInferior.png';
import whatsappImg from '../img/whatsapp.png';
import envelopImg from '../img/envelope-solid.svg';
import phoneImg from '../img/phone-solid.svg';

// Import components

function Footer(){

    const implementedLanguages = ['en', 'es'];

    let language = window.location.pathname.substring(1,3);

    if(!implementedLanguages.includes(language)){ language = 'en'}

    return (
        <div id="footerContainer" className="w-100">

            {/* Footer */}
            <footer className="navbar navbar-dark bg-dark justify-content">

                {/* Footer's header*/}
                <header className="mt-5 col-12 col-md-4 col-lg-2 px-5">
                    <h2 className="text-white"><img src={logoImg} height="100px" alt="logo"/></h2>
                </header>

                {/* Footer's navegation */}
                <div className="mt-5 col-12 col-md-8 col-lg-10">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <p className="nav-link">
                                <img src={phoneImg} width="18px" height="18px"/>
                                {language === 'en' && <React.Fragment>   Mobile:<br/>+57 317 434 4696</React.Fragment>}
                                {language === 'es' && <React.Fragment>   Celular:<br/>+57 317 434 4696</React.Fragment>}
                            </p>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://wa.me/573174344696" target="_blank">
                                <p className="nav-link">
                                    <img src={whatsappImg} width="18px" height="18px"/>
                                    {language === 'en' && <React.Fragment>  WhatsApp:<br/>+57 317 434 4696</React.Fragment>}
                                    {language === 'es' && <React.Fragment>  WhatsApp:<br/>+57 317 434 4696</React.Fragment>}
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <p className="nav-link">
                                <img src={envelopImg} width="18px" height="18px"/>
                                {language === 'en' && <React.Fragment>   Email:<br/>memphis@creadoresdevalor.com</React.Fragment>}
                                {language === 'es' && <React.Fragment>   Email:<br/>memphis@creadoresdevalor.com</React.Fragment>}
                            </p>
                        </li>
                    </ul>
                </div>
            </footer>

        </div>

    );
};

export default Footer;